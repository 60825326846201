<template>
        <div
      v-if="isEdit"
      class="max-w py-3 bg-white border border-gray-200 rounded-lg shadow "
    >
      <div class="flex items-center justify-between">
        <h2
          class="text-2xl font-semibold px-3 text-gray-600  pb-2 border-gray-300"
        >
          {{content?.title}}
        </h2>
      </div>
      <hr class="mb-3" />
      <div v-if="false" class="px-3 mb-2">
        <label class="text-gray-600 "
          >{{content?.customHashtagEdit}}</label
        >     
        <input
          class="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-blue-500 hover:shadow "
          type="text"
          v-model="eventHashtagRef"
        />
      </div>

      <h2 class="mt-4 ml-3">{{content?.subTitle}}</h2>
      <div class="px-3 mb-2 mt-2">
        <label class="text-gray-600  mt-2"
          >{{content?.photo}}</label
        >

        <select
          v-model="timerSettimgRef.photos"
          id="preweddingPause"
          class="block w-full p-2 mb-6 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
        >
          <option v-for="time in times" :key="time" :value="time">
            {{ time }} {{content?.unit}}
          </option>
        </select>
      </div>

      <div class="px-3 mb-2">
        <label class="text-gray-600  mt-2"
          >{{content?.wish}}</label
        >

        <select
          v-model="timerSettimgRef.wish"
          id="wishPause"
          class="block w-full p-2 mb-6 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
        >
          <option v-for="time in times" :key="time" :value="time">
            {{ time }} {{content?.unit}}
          </option>
        </select>
      </div>

      <div class="flex justify-end mt-1 px-3">
        <button
          class="py-1.5 px-3 m-1 text-center bg-blue-700 border rounded-md text-white hover:bg-violet-500 hover:text-gray-100 "
          @click="onSave"
        >
          Save
        </button>
        <button
          class="py-1.5 px-3 m-1 text-center bg-red-700 border rounded-md text-white hover:bg-violet-500 hover:text-gray-100 "
          @click="toggleEditMode"
        >
          Cancel
        </button>
      </div>
    </div>
</template>

<script setup>

const props = defineProps({
  content: { type: Object, default: {}},
  isEdit: { type: Boolean, required: true },
  callback: { type: Function, required: true },
  timerSettimg: { type: Object, required: true },  
  eventHashtag: { type: String, required: true },
});

const timerSettimgRef = ref({...props.timerSettimg})
const eventHashtagRef = ref(props.eventHashtag)
const times = [12, 13, 14, 15, 16, 17, 18, 19, 20];


const emit = defineEmits(["onEdit"]);

const toggleEditMode = () => {
  emit("onEdit", !props.isEdit);
};



const onSave = ()=>{    
    Object.assign(props.timerSettimg, timerSettimgRef.value);    
    toggleEditMode()     
    props.callback({eventHashtag:eventHashtagRef.value})    

    timerSettimgRef.value = {}
    eventHashtagRef.value = ""
}

</script>