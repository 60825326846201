<template>
  <div
    :class="{ 'opacity-40': eventPackage == 'free' }"
    class="max-w py-3 bg-white border border-gray-200 rounded-lg shadow "
  >
    <div class="flex items-center justify-between py-1.5">
      <h2
        class="text-2xl font-semibold px-3 text-gray-600 pb-2 border-gray-300"
      >
        {{content?.title}}
      </h2>
      <button
        v-if="eventPackage !== 'free' && eventStore.isOwner"
        @click="toggleEditMode"
        class="py-1.5 px-3 mb-2 text-center bg-white border rounded-md text-gray-800 mr-2"
      >
        Edit
      </button>
    </div>
    <hr class="mb-3" />
    <div v-if="false" class="px-3 mb-2">
      <label class="text-gray-600 "
        >{{content?.customHashtag}}: {{ props.eventHashtag }}</label
      >
    </div>

    <h2 class="mt-4 ml-3 font-bold">{{content?.subTitle}} </h2>
    <div class="px-3 mb-2 mt-2">
      <label class="text-gray-600  mt-2"
        >{{content?.photo}} {{ timerSettimg.photos }} {{content?.photoEdited}}</label
      >
    </div>

    <div class="px-3 mb-2">
      <label class="text-gray-600  mt-2"
        >{{content?.wish}} {{ timerSettimg.wish }} {{content?.wishEdited}}</label
      >
    </div>
  </div>
</template>

<script setup>
const eventStore = useEventStore();
const props = defineProps({
  content: { type: Object, default: {}},
  isEdit: { type: Boolean, required: true },
  timerSettimg: { type: Object, required: true },
  eventPackage: { type: String, required: true },
  eventHashtag: { type: String, required: true },
});

const emit = defineEmits(["onEdit"]);

const toggleEditMode = () => {
  emit("onEdit", !props.isEdit);
};
</script>
