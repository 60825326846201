<template>
  <AppBlockScreen
    :isShow="eventPackage == 'free' ? true : false"
    @submit="saveChangeOptional"
    class="mx-2 mb-3 rounded-lg shadow"
  >
    <template #description>
      <p class="mt-3">ปรับแต่งข้อมูล</p>
      <p class="">
        สำหรับแพคเกจ
        <span class="underline" @click="$router.push('/package/checkout')"
          >Pro</span
        >
        เท่านั้น
      </p>
    </template>

    <!-- EDIT MODE -->
    <event-optional-edit
      v-if="isEdit"
      :content="content"
      :isEdit="isEdit"
      :eventHashtag="eventHashtag"
      :timerSettimg="timerSettimg"
      :callback="onSave"
      @onEdit="handleChangeEditMode"      
    />

    <!-- SHOW MODE -->
    <event-optional-show
      v-else
      :content="content"
      @onEdit="handleChangeEditMode"
      :isEdit="isEdit"
      :eventHashtag="eventHashtag"
      :timerSettimg="timerSettimg"
      :eventPackage="eventPackage"
    />
  </AppBlockScreen>
</template>
<script setup>
import { useEventStore } from "@/stores/event";
const useEvent = useEventStore();
const props = defineProps([
  "content",
  "eventHashtag",
  "timerSettimg",
  "saveChangeOptional",
]);
const emit = defineEmits(["input-eventHashtag"]);

const isEdit = ref(false);
const eventHashtag = ref();
const eventPackage = computed(() => useEvent.event && useEvent.event.package);

watchEffect(() => {
  if (props.eventHashtag) {
    eventHashtag.value = props.eventHashtag;
  }
});

const handleChangeEditMode = (_isEdit) => {
  isEdit.value = _isEdit;
};

const onSave = (data) => {
  isEdit.value = false;
  emit("input-eventHashtag", data.eventHashtag);
  props.saveChangeOptional();
};
</script>
